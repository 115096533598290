import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Booking } from "../models/booking";
import { store } from "./store";
import { replace } from "lodash";
import bookingStatuses from "app/models/types/bookingStatuses";
import { toast } from "material-react-toastify";

export default class BookingStore {

    bookings: Booking[] = [];
    loading = false; // modal window buttons loading state
    loadingInitial = false; // list view table loading state
    
    constructor() { 
        makeAutoObservable(this);
        this.loadBookings();
    }

    // Computed property - returns an array of bookings sorted by start date, newest first
    get bookingsSorted() {
        return Array.from(this.bookings.values()).sort((a, b) => new Date(b.startTime).valueOf() - new Date(a.startTime).valueOf());
    }

    get bookingsTodaySorted() {
        //todo: change the hours to current roster hours when available
        var bookingToday = this.bookings.filter(b =>             
            new Date(b.startTime) > new Date(new Date().setHours(1)) &&
            new Date(b.startTime) <= new Date(new Date(new Date().setDate(new Date().getDate()+1)).setHours(1))
        );

        bookingToday
        var bookingsTodaySorted = Array.from(bookingToday.values()).sort((a, b) => new Date(a.startTime).valueOf() - new Date(b.startTime).valueOf());
        return bookingsTodaySorted;
    }

    // Load or pre-load all bookings
    listAllBookings = async (isFutureOnly?: boolean) => {
        this.setLoadingInitial(true)
        try {            
            const { data, ...metaData } = await agent.Bookings.list(isFutureOnly? isFutureOnly : true);
            runInAction(() => {
                this.bookings = data;
                this.setCalculatedFields();
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    // loading state setter 
    setLoadingInitial = (state: boolean) => {
        runInAction(() => {
            this.loadingInitial = state;
        })
    }

    // loading state setter 
    setLoading = (state: boolean) => {
        runInAction(() => {
            this.loading = state;
        })
    }

    // load bookings - paginated list of bookings from api
    loadBookings = async (isFutureOnly?: boolean) => {
        this.setLoadingInitial(true)
        try {
            const response = await agent.Bookings.list(isFutureOnly? isFutureOnly : true); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            runInAction(() => {
                this.bookings = response.data;
                this.setCalculatedFields();
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    setCalculatedFields = () => {
      
        const { stopStore: {stops, loadStops}, boatStore: {boats, loadBoats} } = store;
  
            if(stops.length==0)
                loadStops();
            if(boats.length==0)
                loadBoats();
            
            this.bookings.forEach((booking, i) => {
              
                const origin = stops.find(s => s.id == booking.originId);
                const destination = stops.find(s => s.id == booking.destinationId);
                const boat = boats.find(b => b.id == booking.boatId);

                booking.boatDescription = boat?.name || "Boat name not found";
                booking.originDescription = origin?.name || "Stop name not found";
                booking.destinationDescription = destination?.name || "Stop name not found";
                booking.statusDescription = bookingStatuses.find(b => b.id == booking.status)?.name || booking.status + "";
                
                var endDate = new Date(new Date(booking.startTime+"Z").toISOString());
                endDate.setMinutes(endDate.getMinutes() + booking.duration );

                booking.title = "From: " + booking.originDescription + 
                                " To: " + booking.destinationDescription + 
                                " Name: " + booking.bookingName + 
                                " (" + booking.pax + " pax)" +
                                " Status: " + bookingStatuses.find(b => b.id == booking.status)?.name
                                //" On: " + booking.startTime.toString().replace("T", " At: "),replace("Z", "");
                booking.start = booking.startTime + "";
                booking.end = endDate.toISOString().slice(0,19);
          })
      }

    // create booking
    createBooking = async (booking: Booking) => {
        this.setLoading(true)
        try {
            const bookingRequestBody = {
                boatId: booking.boatId,
                originId: booking.originId,
                destinationId: booking.destinationId,
                startTime: booking.startTime,
                duration: booking.duration,
                pax: booking.pax,
                amount: booking.amount,
                bookingName: booking.bookingName,
                bookingEmail: booking.bookingEmail,
                bookingMobile: booking.bookingMobile,
                status: booking.status,
                tenantId: booking.tenantId,
            }
            const response = await agent.Bookings.create(bookingRequestBody);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    // create booking
    createPublicBooking = async (booking: Booking) => {
        this.setLoading(true)
        try {
            const bookingRequestBody = {
                boatId: booking.boatId,
                originId: booking.originId,
                destinationId: booking.destinationId,
                startTime: booking.startTime,
                duration: booking.duration,
                pax: booking.pax,
                amount: booking.amount,
                bookingName: booking.bookingName,
                bookingEmail: booking.bookingEmail,
                bookingMobile: booking.bookingMobile,
                status: booking.status,
                tenantId: booking.tenantId,
            }
            const response = await agent.Bookings.createPublic(bookingRequestBody);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);            
            return response.data;
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }
    
    // create booking
    getSessionBooking = async (sessionId: string) => {

        this.setLoading(true);

        try {            
            const response = await agent.Bookings.getSessionBooking(sessionId);

            if (!response.succeeded) 
                throw new Error(response.messages[0]);

            this.setLoading(false);            
            return response.data;

        } 
        catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    // update booking
    updateBooking = async (booking: Booking) => {
        this.setLoading(true)
        try {
            const response = await agent.Bookings.update(booking);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    // update booking
    updateBookingStatus = async (bookingId: string) => {
        this.setLoading(true)
        try {
            const response = await agent.Bookings.updateStatus(bookingId);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
            return response;
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    // delete booking
    deleteBooking = async (id: string) => {
        this.setLoadingInitial(true)
        try {
            const response = await agent.Bookings.delete(id); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoadingInitial(false)
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false)
            throw error;
        }
    }
}