import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Boat } from "../models/boat";
import { store } from "./store";

export default class BoatStore {
    
    boats: Boat[] = [];
    loading = false; // modal window buttons loading state
    loadingInitial = false; // list view table loading state

    constructor() {
        makeAutoObservable(this);
        this.loadBoats();
    }

    // Computed property - returns an array of drivers sorted by surname, newest first
    get boatsSorted() {
        return Array.from(this.boats.values()).sort((a, b) => ('' + b.name).localeCompare(a.name));
    }

    // Load or pre-load all boats
    listAllBoats = async ( ) => {
        this.setLoadingInitial(true)
        try {            
            const { data, ...metaData } = await agent.Boats.list(); 
            runInAction(() => {
                this.boats = data;
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    // Load boats available between the specified dates
    /*listAvailableBoats = async (start: Date, end: Date): Promise<Boat[]> => {

        const bookings = store.bookingStore.bookings;
        let unavailableBoatIds: string[] = [];

        // Does this boat overlap with a booking?
        bookings.forEach((l: { endDate: any; startDate: any; id: string; }, i: any) => { //todo fix this
            const bookingEnd = l.endDate? l.endDate : new Date("12/12/9999");
            if(l.startDate! < end && start < bookingEnd)
                unavailableBoatIds.push(l.id);
        })

        const availableBoats = this.boats.filter(b => unavailableBoatIds.find(b2 => b2 != b.id))

        return availableBoats;
    }*/

    // loading state setter 
    setLoadingInitial = (state: boolean) => {
        runInAction(() => {
            this.loadingInitial = state;
        })
    }

    // loading state setter 
    setLoading = (state: boolean) => {
        runInAction(() => {
            this.loading = state;
        })
    }

    // load boats - paginated list of boats from api
    loadBoats = async () => {
        this.setLoadingInitial(true)
        try {
            const response = await agent.Boats.list(); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            runInAction(() => {
                this.boats = response.data;
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    // create boat
    createBoat = async (boat: Boat) => {
        this.setLoading(true)
        try {
            const boatRequestBody = {
                Registration: boat.registration,
                //RegistrationState: boat.registrationState,
                //Make: boat.make,
                //Model: boat.model,
                Name: boat.name,
                //Year: boat.year,
                Pax: boat.pax,
                TenantId: boat.tenantId,
            }
            const response = await agent.Boats.create(boatRequestBody);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
            return response.data.id;
        }
        catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    // update boat
    updateBoat = async (boat: Boat) => {
        this.setLoading(true)
        try {
            const response = await agent.Boats.update(boat);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    // delete boat
    deleteBoat = async (id: string) => {
        this.setLoadingInitial(true)
        try {
            const response = await agent.Boats.delete(id); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoadingInitial(false)
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false)
            throw error;
        }
    }
}