import { createContext, useContext } from "react";
import AppUserStore from "./appUserStore";
import CommonStore from "./commonStore";
import TenantStore from "./tenantStore";
import CurrentUserStore from "./currentUserStore";
import DriverStore from "./driverStore";
import BookingStore from "./bookingStore";
import NotificationStore from "./notificationStore";
import PaymentStore from "./paymentStore";
import BoatStore from "./boatStore";
import NoteStore from "./noteStore";
import InsuranceStore from "./insuranceStore";
import FileStore from "./fileStore";
import VehicleMaintenanceStore from "./vehicleMaintenanceStore";
import StopStore from "./stopStore";
import TripStore from "./tripStore";

// base Mobx store
interface Store {
    commonStore: CommonStore;
    currentUserStore: CurrentUserStore;
    appUserStore: AppUserStore;
    tenantStore: TenantStore;
    driverStore: DriverStore;
    boatStore: BoatStore;
    bookingStore: BookingStore;
    paymentStore: PaymentStore;
    notificationStore: NotificationStore;
    noteStore: NoteStore;
    insuranceStore: InsuranceStore;
    fileStore: FileStore;
    vehicleMaintenanceStore: VehicleMaintenanceStore;
    stopStore: StopStore;
    tripStore: TripStore;
}

export const store: Store = {
    commonStore: new CommonStore(),
    currentUserStore: new CurrentUserStore(),
    appUserStore: new AppUserStore(),
    tenantStore: new TenantStore(),
    driverStore: new DriverStore(),
    boatStore: new BoatStore(),
    bookingStore: new BookingStore(),
    paymentStore: new PaymentStore(),
    notificationStore: new NotificationStore(),
    noteStore: new NoteStore(),
    insuranceStore: new InsuranceStore(),
    fileStore: new FileStore(),
    vehicleMaintenanceStore: new VehicleMaintenanceStore(),
    stopStore: new StopStore(),
    tripStore: new TripStore(),
}

export const StoreContext = createContext(store); 

export function useStore() { 
    return useContext(StoreContext);
}