import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Note } from "../models/note";
import { SearchParams } from "../models/searchParams";
import { PaginatedResult } from '../models/responseWrappers';

export default class NoteStore {
    note!: Note | null;
    notes: Note[] = [];
/*
    vehicleNotes: Note[] = [];
    driverNotes: Note[] = [];    
    bookingNotes: Note[] = [];
    paymentNotes: Note[] = [];
    notificationNotes: Note[] = [];
    userSettingNotes: Note[] = [];
*/
    noteMetaData: Omit<PaginatedResult<Note>, 'data'> | null = null;

    loading = false; // modal window buttons loading state
    loadingInitial = false; // list view table loading state
    noteStoreLoaded = false;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
        this.listAllNotes();
    }

    // Load or pre-load all notes
    listAllNotes = async ( ) => {
        this.setLoadingInitial(true)
        try {            
            const { data, ...metaData } = await agent.Notes.list(); 
            runInAction(() => {
                this.notes = data;
            })
            this.setLoadingInitial(false);
            this.noteStoreLoaded = true;
            //this.splitNotes();
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            this.noteStoreLoaded = false;
        }
    }

    splitNotes = ( ) => {/*
        this.boatNotes = this.notes.filter(n => n.entityType == noteEntities.find(n => n.name == "Boat")!.id);
        this.driverNotes = this.notes.filter(n => n.entityType == noteEntities.find(n => n.name == "Driver")!.id);
        this.paymentNotes = this.notes.filter(n => n.entityType == noteEntities.find(n => n.name == "Payment")!.id);
        this.bookingNotes = this.notes.filter(n => n.entityType == noteEntities.find(n => n.name == "Booking")!.id);
        this.notificationNotes = this.notes.filter(n => n.entityType == noteEntities.find(n => n.name == "Notification")!.id);
        this.userSettingNotes = this.notes.filter(n => n.entityType == noteEntities.find(n => n.name == "userSetting")!.id);*/
    }

    // loading state setter 
    setLoadingInitial = (state: boolean) => {
        runInAction(() => {
            this.loadingInitial = state;
        })
    }
    // loading state setter 
    setLoading = (state: boolean) => {
        runInAction(() => {
            this.loading = state;
        })
    }
    // set pagination meta data
    setNoteMetaData = (metaData: Omit<PaginatedResult<Note>, 'data'>) => {
        runInAction(() => {
            this.noteMetaData = metaData;
        })
    }

    // load notes - paginated list of notes from api
    // ********** WILL NOT RETURN DELETED NOTES **********
    loadNotes = async (
        keyword: string,
        pageNumber: number = 1,
        pageSize: number = 0,
    ) => {
        this.setLoadingInitial(true)
        try {
            const params: SearchParams = {
                keyword,
                pageNumber,
                pageSize // Ignored for notes
            }
            const { data, ...metaData } = await agent.Notes.search(params); 
            runInAction(() => {
                this.notes = data;
            })
            this.setNoteMetaData(metaData);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    createNote = async (note: Note) => {
        this.setLoading(true);
        try {
            const noteRequestBody = {
                id: 'note.id',
                entityType: note.entityType,
                entityId: note.entityId,
                noteText: note.noteText,
                TenantId: note.tenantId,
            }
            const response = await agent.Notes.create(noteRequestBody);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    // update note
    updateNote = async (note: Note) => {
        this.setLoading(true)
        try {
            const response = await agent.Notes.update(note);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    // delete note
    deleteNote = async (id: string) => {
        this.setLoadingInitial(true)
        try {
            const response = await agent.Notes.delete(id); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoadingInitial(false)
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false)
            throw error;
        }
    }
}