import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Stop } from "../models/stop";
import { FileDetailsDTO } from "app/models/fileDTO";

export default class StopStore {
    
    stops: Stop[] = [];

    loading = false; // modal window buttons loading state
    loadingInitial = false; // list view table loading state
    loadingStopsLicense = false;

    constructor() {
        makeAutoObservable(this);
        this.loadStops();
    }

    // Computed property - returns an array of stops sorted by surname, newest first
    get stopsSorted() {
        return Array.from(this.stops.values()).sort((a, b) => ('' + a.name).localeCompare(b.name));
    }

    // Load or pre-load all stops
    listAllStops = async ( ) => {
        this.setLoadingInitial(true)
        try {            
            const { data, ...metaData } = await agent.Stops.list(); 
            runInAction(() => {
                this.stops = data;
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    // loading state setter 
    setLoadingInitial = (state: boolean) => {
        runInAction(() => {
            this.loadingInitial = state;
        })
    }

    // loading state setter 
    setLoading = (state: boolean) => {
        runInAction(() => {
            this.loading = state;
        })
    }
    
    // load stops - paginated list of stops from api
    loadStops = async () => {
        this.setLoadingInitial(true)
        try {
            const response = await agent.Stops.list(); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            runInAction(() => {
                this.stops = response.data;
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }
    
    // create stop
    createStop = async (stop: Stop) => {
        this.setLoading(true)
        try {
            const stopRequestBody = {
                Name: stop.name,
                Address: stop.address,
                XCoordinate: stop.xCoordinate,
                YCoordinate: stop.yCoordinate,
                IsPrivate: stop.isPrivate,
                TenantId: stop.tenantId,
            }
            const response = await agent.Stops.create(stopRequestBody);

            if (!response.succeeded){
                throw new Error(response.messages[0]);
            }
            this.setLoading(false);
            return response.data.id;

        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    // update stop
    updateStop = async (stop: Stop) => {
        this.setLoading(true)
        try {
            const response = await agent.Stops.update(stop);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
            return true;
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    // delete stop
    deleteStop = async (id: string) => {
        this.setLoadingInitial(true)
        try {
            const response = await agent.Stops.delete(id); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoadingInitial(false)
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false)
            throw error;
        }
    }
}