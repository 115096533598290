import { Roles } from "app/models/roles";
import Icons from "../../icons/sidebar";
import { DirectionsBoat, LocalActivity, LocationOn, Route, Construction } from "@mui/icons-material";

// list of navigation items that appear in sidebar
const navList = [
  {
    title: "Stops",
    Icon: LocationOn,
    path: "/stops",
    roles: null,
    topDivider: false
  },
  {
    title: "Trip",
    Icon: Route,
    path: "/trips",
    roles: null,
    topDivider: false
  },
  {
    title: "Boats",
    Icon: DirectionsBoat,
    path: "/boats",
    roles: null,
    topDivider: true
  },
  {
    title: "Drivers",
    Icon: Icons.DriverIcon,
    path: "/drivers",
    roles: null,
    topDivider: false
  },
  {
    title: "Bookings",
    Icon: LocalActivity,
    path: "/bookings",
    roles: null,
    topDivider: false
  },/*
  {
    title: "Payments",
    Icon: Icons.PaymentIcon,
    path: "/payments",
    roles: null,
    topDivider: false
  },*/
  {
    title: "Notifcations",
    Icon: Icons.NotificationsIcon,
    path: "/notifications",
    roles: null,
    topDivider: true
  },/* 
  {
    title: "Maintenance",
    Icon: Construction,
    path: "/vehicleMaintenance",
    roles: null,
    topDivider: true
  },
  {
    title: "Insurance",
    Icon: PrivacyTipOutlined,
    path: "/insurances",
    roles: null,
    topDivider: false
  },*/
  {
    title: "Users",
    Icon: Icons.UserProfileIcon,
    path: "/users",
    roles: [Roles.root, Roles.admin], // for restricting to certian roles
    topDivider: true
  },
  {
    title: "Tenants",
    Icon: Icons.AccountSettingsIcon,
    path: "/tenants",
    roles: [Roles.root],
    topDivider: false
  }
];

export default navList;
