import AccountSettingsIcon from "./AccountSettings";
import DirectionsCar from "./DirectionsCar";
import DriverIcon from "./DriverIcon";
import NotificationsIcon from "./NotificationsIcon"
import PagesIcon from "./Pages";
import PaymentIcon from "./PaymentIcon";
import UserManagementIcon from "./UserManagement";
import UserProfileIcon from "./UserProfile";
import UserSettingsIcon from "./UserSettingsIcon"

export default {
  AccountSettingsIcon,
  DriverIcon,
  DirectionsCar,
  NotificationsIcon,
  PagesIcon,
  PaymentIcon,
  UserProfileIcon,
  UserManagementIcon,
  UserSettingsIcon
};

