import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'material-react-toastify';
import { store } from '../stores/store';
import { TokenData, UserLogin, ForgotPasswordRequest, ResetPasswordRequest} from '../models/auth';
import { User, RegisterUserRequest} from '../models/user';
import { ChangePasswordRequest, UpdateProfileRequest, UpdatePreferencesRequest, ChangeProfileImageRequest, CurrentUser } from '../models/currentUser';
import { SearchParams } from '../models/searchParams';
import { PaginatedResult, Result } from '../models/responseWrappers';
import { CreateTenantRequest, Tenant } from '../models/tenant';
import { AddTripRequest, Trip } from '../models/trip';
import { AddStopRequest, Stop } from '../models/stop';
import { AddBoatRequest, Boat } from '../models/boat';
import { AddVehicleMaintenanceRequest, VehicleMaintenance } from '../models/vehicleMaintenance';
import { AddDriverRequest, Driver } from '../models/driver';
import { AddInsuranceRequest, Insurance } from '../models/insurance';
import { AddBookingRequest, Booking } from '../models/booking';
import { AddNotificationRequest, Notification, UpdateNotificationRequest } from '../models/notification';
import { AddPaymentRequest, Payment } from '../models/payment';
import { AddNoteRequest, Note } from '../models/note';
import sleep from 'app/utils/sleep';
import { FileDTO, FileDetailsDTO } from "../models/fileDTO";
import JSZip from 'jszip';
import getBase64 from 'app/utils/getBase64';
import { RootSettings } from 'app/models/rootSettings';


// Base URL
// -- development: https://localhost:7250/api
// -- production: (your domain)
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// Send up the token with every request, when there is a token
axios.interceptors.request.use(config => {
    const token = store.commonStore.token;
    config.headers = {
        Tenant: store.commonStore.tenant ?? '',
    };
    if (token) config.headers.Authorization = `Bearer ${token}`
    return config;
})

// Axios reponse interceptors
axios.interceptors.response.use(async response => {

    //if (process.env.NODE_ENV === 'development') await sleep(1000); // Artifical delay for development
    return response;
    
}, 
    (error: AxiosError) => { // Basic error handling for 400 and 500 type errors

    if(error.response == undefined){
        toast.error('' + error, {toastId: 'Undefined error.response'});
        return Promise.reject(error);
    }

    const { data, status } = error.response!;
    switch (status) {
        case 400:
            toast.error('Bad request: ' + data.erros[0], {toastId: 'Error.400'});
            break;
        case 401:
            //toast.error('Error code 401: unauthorized', {toastId: 'Error.401'});
            break;
        case 404:
            //toast.error('Error code 404: '+ error.message, {toastId: 'Error.404'});
            break;
        case 500:
            toast.error('Error code 500: internal server error', {toastId: 'Error.500'});
            console.log(data);
            break;
        default:
            toast.error('Error code ' + status + ': unknown error', {toastId: 'UnknownError'});
            console.log(data);
            break;
    }
    return Promise.reject(error);
})
const responseBody = <T>(response: AxiosResponse<T>) => response.data;

// Axios Base
const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),

    download: <T>(url: string) => axios({
        url: url,
        method: 'get',
        responseType: 'json', //arraybuffer, blob, document, json, text, stream
      })
      .then(async response => {
        const file: FileDetailsDTO = response.data[0];
        var b64 = "data:" + file.FileType + ";base64," + file.FileContent;
        
        return b64;
      })
      .catch(err => {
        console.log(err);
      }),

    downloadMultiple: <T>(url: string) => axios({
        url: url,
        method: 'get',
        responseType: 'json', // arraybuffer, blob, document, json, text, stream
      })
      .then(async response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
      }),
      
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}, options?: {}) => axios.put<T>(url, body, options).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
}

// Authentication & Profile Management (Current User)
const Account = {
    current: () => requests.get<Result<CurrentUser>>('/identity/profile'),
    login: (user: UserLogin) => requests.post<Result<TokenData>>(`/tokens`, user),
    update: (user: UpdateProfileRequest) => requests.put<Result<CurrentUser>>(`/identity/profile`, user),
    updateProfile: (user: UpdateProfileRequest) => requests.put<Result>(`/identity/profile`, user),
    changeProfileImage: (changeProfileImageRequest: ChangeProfileImageRequest)=> {
        let formData = new FormData();
        Object.entries(changeProfileImageRequest).forEach( ([key, val]) => {
            formData.append(key, val);
        })
        return requests.put<Result<string>>('/identity/profile-image', formData, {
            headers: { 'Content-type': 'multipart/form-data' }
        })
    },
    updatePreferences: (updatePreferencesRequest: UpdatePreferencesRequest) => requests.put<Result>(`/identity/preferences`, updatePreferencesRequest),
    changePassword: (changePasswordRequest: ChangePasswordRequest) => requests.put<Result>(`/identity/change-password`, changePasswordRequest),
    forgotPassword: (forgotPasswordRequest: ForgotPasswordRequest) => requests.post<Result>(`/identity/forgot-password`, forgotPasswordRequest),
    resetPassword: (resetPasswordRequest: ResetPasswordRequest) => requests.post<Result>(`/identity/reset-password`, resetPasswordRequest),
}

// File upload
const Files = {
    uploadFile: (fileDTO: FileDTO)=> {
        let formData = new FormData(); // form data to upload a file
        Object.entries(fileDTO).forEach( ([key, val]) => {
            formData.append(key, val);
        })
        return requests.put<Result<string>>('/file/upload', formData, {
            headers: { 'Content-type': 'multipart/form-data' }
        })
    },
    download: (req: FileDTO) => requests.download<Result<any>>(`/file/${req.id}/${req.documentType}/${req.tenantId}`),
    downloadMultiple: (req: FileDTO) => requests.downloadMultiple<Result<any>>(`/file/${req.id}/${req.documentType}/${req.tenantId}`),
    delete: (id: string, documentType: string, documentName: string, tenantId: string) => requests.del<Result<string>>(`/file/single/${id}/${documentType}/${documentName}/${tenantId}`), //turn request into FileDTO
    deleteNewest: (id: string, documentType: string, documentName: string, tenantId: string) => requests.del<Result<string>>(`/file/single/${id}/${documentType}/${documentName}/${tenantId}`),
}// 

// Trip
const Trips = {
    list: () => requests.get<Result<Trip[]>>(`/trips`),
    //listAvailableTrips: (params: DateRange) => requests.post<Result<Trip[]>>(`/trips/ListAvailableTrips`, params),
    search: (params: SearchParams) => requests.post<PaginatedResult<Trip>>(`/trips/TripListPaginated`, params), // paginated list handled server-side
    create: (trip: AddTripRequest) => requests.post<Result<Trip>>('/trips', trip),
    details: (id: string) => requests.get<Result<Trip>>(`/trips/${id}`),
    update: (trip: Trip) => requests.put<Result<Trip>>(`/trips/${trip.id}`, trip),
    delete: (id: string) => requests.del<Result<string>>(`/trips/${id}`),
}

// Stop
const Stops = {
    list: () => requests.get<Result<Stop[]>>(`/stops`),
    //listAvailableStops: (params: DateRange) => requests.post<Result<Stop[]>>(`/stops/ListAvailableStops`, params),
    search: (params: SearchParams) => requests.post<PaginatedResult<Stop>>(`/stops/StopListPaginated`, params), // paginated list handled server-side
    create: (stop: AddStopRequest) => requests.post<Result<Stop>>('/stops', stop),
    details: (id: string) => requests.get<Result<Stop>>(`/stops/${id}`),
    update: (stop: Stop) => requests.put<Result<Stop>>(`/stops/${stop.id}`, stop),
    delete: (id: string) => requests.del<Result<string>>(`/stops/${id}`),
}

// Boats
const Boats = {
    list: () => requests.get<Result<Boat[]>>(`/boats`),
    //listAvailableBoats: (params: DateRange) => requests.post<Result<Boat[]>>(`/boats/ListAvailableBoats`, params),
    search: (params: SearchParams) => requests.post<PaginatedResult<Boat>>(`/boats/BoatListPaginated`, params), // paginated list handled server-side
    create: (boat: AddBoatRequest) => requests.post<Result<Boat>>('/boats', boat),
    details: (id: string) => requests.get<Result<Boat>>(`/boats/${id}`),
    update: (boat: Boat) => requests.put<Result<Boat>>(`/boats/${boat.id}`, boat),
    delete: (id: string) => requests.del<Result<string>>(`/boats/${id}`),
}

// Vehicle Maintenance
const VehicleMaintenances = {
    list: () => requests.get<Result<VehicleMaintenance[]>>(`/vehicleMaintenances`),
    search: (params: SearchParams) => requests.post<PaginatedResult<VehicleMaintenance>>(`/vehicleMaintenances/VehicleMaintenancesListPaginated`, params), // paginated list handled server-side
    create: (vehicleMaintenance: AddVehicleMaintenanceRequest) => requests.post<Result<VehicleMaintenance>>('/vehicleMaintenances', vehicleMaintenance),
    details: (id: string) => requests.get<Result<VehicleMaintenance>>(`/vehicleMaintenances/${id}`),
    update: (vehicleMaintenance: VehicleMaintenance) => requests.put<Result<VehicleMaintenance>>(`/vehicleMaintenances/${vehicleMaintenance.id}`, vehicleMaintenance),
    delete: (id: string) => requests.del<Result<VehicleMaintenance>>(`/vehicleMaintenances/${id}`),
}

// Drivers
const Drivers = {
    list: () => requests.get<Result<Driver[]>>(`/drivers`),
    search: (params: SearchParams) => requests.post<PaginatedResult<Driver>>(`/drivers/DriverListPaginated`, params), // paginated list handled server-side
    create: (driver: AddDriverRequest) => requests.post<Result<Driver>>('/drivers', driver),
    details: (id: string) => requests.get<Result<Driver>>(`/drivers/${id}`),
    update: (driver: Driver) => requests.put<Result<Driver>>(`/drivers/${driver.id}`, driver),
    delete: (id: string) => requests.del<Result<string>>(`/drivers/${id}`),
}

// Bookings
const Bookings = {
    list: (isFutureOnly: boolean) => requests.get<Result<Booking[]>>(`/bookings/GetBookingsPublic/${isFutureOnly}`),
    search: (params: SearchParams) => requests.post<PaginatedResult<Booking>>(`/bookings/BookingListPaginated`, params), // paginated list handled server-side
    create: (booking: AddBookingRequest) => requests.post<Result<Booking>>('/bookings', booking),
    createPublic: (booking: AddBookingRequest) => requests.post<Result<string>>('/bookings/CreatePublicBooking', booking),
    getSessionBooking: (sessionId: string) => requests.post<Result<Booking>>(`/bookings/success/${sessionId}`, ""),
    details: (id: string) => requests.get<Result<Booking>>(`/bookings/${id}`),
    update: (booking: Booking) => requests.put<Result<Booking>>(`/bookings/${booking.id}`, booking),
    updateStatus: (bookingId: string) => requests.post<Result<Booking>>(`/bookings/BookingStatus/${bookingId}`, ""),
    delete: (id: string) => requests.del<Result<string>>(`/bookings/${id}`),
}

// Notifications
const Notifications = {
    list: () => requests.get<Result<Notification[]>>(`/notifications`),
    search: (params: SearchParams) => requests.post<PaginatedResult<Notification>>(`/notifications/NotificationListPaginated`, params), // paginated list handled server-side
    getBalance: () => requests.get<Result<number>>(`/notifications/SMSBalance`), // get remaining SMS credit/balance
    create: (notification: AddNotificationRequest) => requests.post<Result<Notification>>('/notifications', notification),
    details: (id: string) => requests.get<Result<Notification>>(`/notifications/${id}`),
    update: (notification: UpdateNotificationRequest) => requests.put<Result<Notification>>(`/notifications/${notification.Id}`, notification),
    delete: (id: string) => requests.del<Result<string>>(`/notifications/${id}`),
}

// Payments
const Payments = {
    list: () => requests.get<Result<Payment[]>>(`/payments`),
    search: (params: SearchParams) => requests.post<PaginatedResult<Payment>>(`/payments/PaymentListPaginated`, params), // paginated list handled server-side
    create: (payment: AddPaymentRequest) => requests.post<Result<Payment>>('/payments', payment),
    details: (id: string) => requests.get<Result<Payment>>(`/payments/${id}`),
    update: (payment: Payment) => requests.put<Result<Payment>>(`/payments/${payment.id}`, payment),
    delete: (id: string) => requests.del<Result<string>>(`/payments/${id}`),
}

// Notes
const Notes = {
    list: () => requests.get<Result<Note[]>>(`/notes`),
    search: (params: SearchParams) => requests.post<PaginatedResult<Note>>(`/notes/NoteListPaginated`, params), // paginated list handled server-side
    create: (note: AddNoteRequest) => requests.post<Result<Note>>('/notes', note),
    details: (id: string) => requests.post<Result<Note>>('/notes/', id),
    update: (note: Note) => requests.put<Result<Note>>(`/notes/${note.id}`, note),
    delete: (id: string) => requests.del<Result<string>>(`/notes/${id}`),
}

// Insurances
const Insurances = {
    list: () => requests.get<Result<Insurance[]>>(`/insurances`),
    search: (params: SearchParams) => requests.post<PaginatedResult<Insurance>>(`/insurances/InsuranceListPaginated`, params), // paginated list handled server-side
    create: (insurance: AddInsuranceRequest) => requests.post<Result<Insurance>>('/insurances', insurance),
    details: (id: string) => requests.post<Result<Insurance>>('/insurances/', id),
    update: (insurance: Insurance) => requests.put<Result<Insurance>>(`/insurances/${insurance.id}`, insurance),
    delete: (id: string) => requests.del<Result<string>>(`/insurances/${id}`),
}

// User Management
const Users = {
    list: () => requests.get<Result<User[]>>('/identity/'), // full list for client-side pagination
    create: (appUser: RegisterUserRequest) => requests.post<Result<User>>(`/identity/register`, appUser),
    details: (id: string) => requests.get<Result<User>>(`/identity/user/${id}`),
    update: (user: User) => requests.put<Result<User>>(`/identity/user/${user.id}`, user),
    delete: (id: string) => requests.del<Result<string>>(`/identity/user/${id}`),
}

// Tenant Management
const Tenants = {
    list: () => requests.get<Result<Tenant[]>>('/tenants'), // full list for client-side pagination
    details: (id: string) => requests.get<Result<Tenant>>(`/tenants/${id}`),
    create: (tenant: CreateTenantRequest) => requests.post<Result<Tenant>>(`/tenants`, tenant),
    update: (tenant: Tenant) => requests.put<Result<Tenant>>(`/tenants/`, tenant), 
}

// Root Management
const Root = {
    list: () => requests.get<Result<RootSettings>>('/identity/root-settings'),
    update: (rootSettings: RootSettings) => requests.put<Result<RootSettings>>(`/identity/root-settings`, rootSettings), 
}

const agent = {
    Account,
    Drivers,
    Files,
    Insurances,
    Bookings,
    Notes,
    Notifications,
    Payments,
    Root,
    Stops,
    Trips,
    Tenants,
    Users,
    Boats,
    VehicleMaintenances,
}

export default agent;