import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Insurance } from "../models/insurance";
import { SearchParams } from "../models/searchParams";
import { PaginatedResult } from '../models/responseWrappers';

export default class InsuranceStore {
    insurances: Insurance[] = [];
    insuranceMetaData: Omit<PaginatedResult<Insurance>, 'data'> | null = null;

    loading = false; // modal window buttons loading state
    loadingInitial = false; // list view table loading state
    insuranceStoreLoaded = false;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
        this.listAllInsurances();
    }

    // Computed property - returns an array of users sorted by expiry date, newest first
    get insurancesSorted() {
        return Array.from(this.insurances.values()).sort((a, b) => new Date(b.expiryDate).valueOf() - new Date(a.expiryDate).valueOf());
    }

    // Load or pre-load all insurances
    listAllInsurances = async ( ) => {
        this.setLoadingInitial(true)
        try {            
            const { data, ...metaData } = await agent.Insurances.list(); 
            runInAction(() => {
                this.insurances = data;
            })
            this.setLoadingInitial(false);
            this.insuranceStoreLoaded = true;
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
            this.insuranceStoreLoaded = false;
        }
    }

    // loading state setter 
    setLoadingInitial = (state: boolean) => {
        runInAction(() => {
            this.loadingInitial = state;
        })
    }
    // loading state setter 
    setLoading = (state: boolean) => {
        runInAction(() => {
            this.loading = state;
        })
    }
    // set pagination meta data
    setInsuranceMetaData = (metaData: Omit<PaginatedResult<Insurance>, 'data'>) => {
        runInAction(() => {
            this.insuranceMetaData = metaData;
        })
    }

    // load insurances - paginated list of insurances from api
    loadInsurances = async (
        pageNumber: number = 1,
        pageSize: number = 5,
        keyword: string = ''
    ) => {
        this.setLoadingInitial(true)
        try {
            const params: SearchParams = {
                pageNumber,
                pageSize,
                keyword
            }
            const { data, ...metaData } = await agent.Insurances.search(params); 
            runInAction(() => {
                this.insurances = data;
            })
            this.setInsuranceMetaData(metaData);
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    // create insurance
    createInsurance = async (insurance: Insurance) => {
        this.setLoading(true)
        try {
            const insuranceRequestBody = {
                EntityType: insurance.entityType,
                EntityId: insurance.entityId,
                Insurer: insurance.insurer,
                PolicyNumber: insurance.policyNumber,
                ValidFrom: insurance.validFrom,
                ExpiryDate: insurance.expiryDate,
                TenantId: insurance.tenantId,
            }
            const response = await agent.Insurances.create(insuranceRequestBody);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    // update insurance
    updateInsurance = async (insurance: Insurance) => {
        this.setLoading(true)
        try {
            const response = await agent.Insurances.update(insurance);
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
            throw error;
        }
    }

    // delete insurance
    deleteInsurance = async (id: string) => {
        this.setLoadingInitial(true)
        try {
            const response = await agent.Insurances.delete(id); 
            if (!response.succeeded) throw new Error(response.messages[0]);
            this.setLoadingInitial(false)
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false)
            throw error;
        }
    }
}